import { ref } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import { Logger } from '~/helpers/logger';
import { useBlogStore } from '~/modules/storyblok/stores/blog';
import { mapCategoriesFromApi, buildCategoryTree } from '~/helpers/blog/traverseCategories';
import type { CategoryTree, Category } from '~/helpers/blog/traverseCategories';
import useStoryblokView from '~/modules/storyblok/composables/useStoryblokView';

export function useBlogCategories() {
  const { getSubCategoriesByParentFullSlug, getBlogCategories } = useStoryblokView();
  const loading = ref(false);
  const error = ref({ load: null });
  const blogStore = useBlogStore();
  const { categories } = storeToRefs(blogStore);
  const mainStoreCategories = categories?.value.main;
  const storeCategoriesTree = categories?.value.tree;

  const mainCategories = ref<Category[]>([]);
  const categoryTree = ref<CategoryTree>({});

  const load = async () => {
    error.value.load = null;
    loading.value = true;

    Logger.debug('useConfig/load');

    try {
      if (mainStoreCategories.length > 0) {
        mainCategories.value = mainStoreCategories;
        categoryTree.value = storeCategoriesTree;
      } else {
        const { stories: mainCategoriesData } = await getBlogCategories({
          params: {
            sort_by: 'created_at:desc',
            per_page: 100,
            excluding_fields: 'SEO,Title,Banner,infoTiles,sortOrder,Description,infoTilesBg,banerUSERCOM,banerUSERCOM2,LinkedArticles',
          },
        });
        mainCategories.value = mapCategoriesFromApi(mainCategoriesData);

        const { stories: childCategoriesData } = await getSubCategoriesByParentFullSlug({
          params: {
            sort_by: 'created_at:desc',
            level: 3,
            per_page: 100,
            excluding_fields: 'component,_editable,_uid',
          },
        });
        const currentCategoryTree = buildCategoryTree(mapCategoriesFromApi(childCategoriesData));
        categoryTree.value = currentCategoryTree;

        blogStore.$patch((state) => {
          state.categories = {
            main: mainCategories.value,
            tree: currentCategoryTree,
          };
        });
      }
    } catch (err) {
      Logger.debug('[ERROR] useBlogCategory/load', err);
      error.value.load = err;
    } finally {
      loading.value = false;
    }
  };

  return {
    load,
    loading,
    mainCategories,
    categoryTree,
  };
}

export default useBlogCategories;
