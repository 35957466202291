import { defineStore } from 'pinia';
import type { Category, CategoryTree } from '~/helpers/blog/traverseCategories';

interface BlogState {
  categories: {
    main: Category[];
    tree: CategoryTree;
  };
}

export const useBlogStore = defineStore('blog', {
  state: (): BlogState => ({
    categories: {
      main: [],
      tree: {},
    },
  }),
});
