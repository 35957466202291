export function scrollToElement(selector:string) {
  const element = document.querySelector(selector);
  if (!element) {
    return;
  }
  const headerOffset = 100;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = (elementPosition + window.scrollY) - headerOffset;

  if (element) {
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
}
