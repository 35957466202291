var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClose),expression:"handleClose"}],class:("relative product-dropdown product-dropdown--" + _vm.size)},[_c('div',{staticClass:"product-dropdown__background"},[_c('div',{staticClass:"product-dropdown__background-inner duration-300 ease-out bg-white",class:{
        'product-dropdown__background-inner--active': _vm.isOpen,
        'product-dropdown__background-inner--minimal': _vm.variant === 'minimal',
      }},[_c('button',{staticClass:"product-dropdown__button w-full flex items-center justify-between",attrs:{"type":"button"},on:{"click":_vm.toggleIsOpen}},[_c('span',{staticClass:"flex"},[(!_vm.noIcon)?_c('SfImage',{staticClass:"product-dropdown__icon mr-4",attrs:{"src":_vm.addBasePath("/icons/green-info.svg"),"alt":"info","width":"24","height":"24"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"product-dropdown__heading",class:{
              'font-bold': _vm.variant === 'normal',
            }},[_vm._v(_vm._s(_vm.title))])],1),_vm._v(" "),_c('SfImage',{staticClass:"transition-transform duration-300 ease-out product-dropdown__arrow-icon",class:{
            '-rotate-90': !_vm.isOpen,
            'rotate-90': _vm.isOpen,
          },attrs:{"src":_vm.addBasePath("/icons/arrow-left-green.svg"),"alt":"info","width":"9","height":"16","loading":"lazy","fetchpriority":"low"}})],1),_vm._v(" "),_c('div',{ref:"productContent",staticClass:"duration-300 ease-out product-dropdown__content",class:{
          'product-dropdown__content--opened': _vm.isOpen,
        },style:({
          'height': _vm.isOpen ? _vm.getContentHeight() + 'px' : '0'
        })},[_c('div',{staticClass:"product-dropdown__content-inner",class:{
            'product-dropdown__content-inner--no-padding': _vm.noContentPadding,
          }},[_vm._t("default")],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }