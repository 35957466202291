










































































import {
  defineComponent,
  PropType,
  ref,
} from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';

import {
  SfImage,
} from '@storefront-ui/vue';

type DropdownSizes = 'md' | 'lg';
type DropdownVariants = 'normal' | 'minimal';

export default defineComponent({
  name: 'ProductDropdown',
  components: { SfImage },
  directives: { clickOutside },
  transition: 'fade',
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    size: {
      type: String as PropType<DropdownSizes>,
      default: 'md',
    },
    variant: {
      type: String as PropType<DropdownVariants>,
      default: 'normal',
    },
    noIcon: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    noContentPadding: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup() {
    const isOpen = ref(false);

    const toggleIsOpen = () => {
      isOpen.value = !isOpen.value;
    };

    const handleClose = () => {
      isOpen.value = false;
    };

    const productContent = ref<HTMLDivElement | null>(null);
    const getContentHeight = () => productContent.value?.scrollHeight;

    return {
      addBasePath,
      handleClose,
      toggleIsOpen,
      isOpen,
      getContentHeight,
      productContent,
    };
  },
});
