export interface Category {
  name: string;
  full_slug: string;
  uuid: string;
}
export interface CategoryTree {
  [category: string]: {
    childCategories: Category[];
  };
}

export function mapCategoriesFromApi(categories: any): Category[] {
  return categories.map((category: any) => ({
    name: category.name,
    full_slug: category.full_slug,
    uuid: category.uuid,
  }));
}

export function buildCategoryTree(categories: Category[]): CategoryTree {
  const categoryTree: CategoryTree = {};

  // Iterate through each full slug
  categories.forEach((category) => {
    const parts: string[] = category.full_slug.split('/');

    // Traverse the category tree and update it
    let currentLevel: any = categoryTree;
    for (let i = 1; i < parts.length - 1; i++) {
      const currentCategory = `${parts.slice(0, i + 1).join('/')}/`;
      if (!currentLevel[currentCategory]) {
        currentLevel[currentCategory] = { children: [] };
      }

      currentLevel[currentCategory].children.push(category);
      currentLevel = currentLevel[currentCategory];
    }
  });

  return categoryTree;
}
