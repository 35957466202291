import type { MetaInfo } from 'vue-meta';
import type { Pagination } from '~/composables/types';
import { resolveUrl } from '~/modules/storyblok/helpers/resolveUrl';

export interface PageInterface {
  metaTitle?: string;
  metaDescription?: string;
  metaKeywords?: string;
  metaImage?: string;
  baseUrl?: string;
  linkPrev?: string;
  linkNext?: string;
}

export const getMetaInfo = ({
  page,
}: {
  page: PageInterface, isNoIndex?: boolean
}): MetaInfo => {
  if (!page) {
    return null;
  }
  const seoTags: MetaInfo = {
    meta: [],
    link: [],
  };

  const {
    metaTitle, metaDescription, metaKeywords, metaImage, baseUrl, linkPrev, linkNext,
  } = page;

  if (metaTitle) {
    seoTags.title = `${metaTitle} - Agrosimex.pl`;
    seoTags.meta.push({
      hid: 'og:title',
      name: 'og:title',
      content: `${metaTitle} - Agrosimex.pl`,
    });
  }
  if (metaDescription) {
    const descr = metaDescription.length > 160 ? metaDescription.replace(/<[^>]+>/g, ' ').replace(/\s{2,10}/g, ' ').slice(0, 160) : metaDescription;
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: descr,
    }, {
      hid: 'og:description',
      property: 'og:description',
      content: descr,
    });
  }
  if (metaKeywords) {
    seoTags.meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: metaKeywords,
    });
  }
  if (metaImage) {
    seoTags.meta.push({
      hid: 'og:image',
      property: 'og:image',
      content: metaImage,
    });
  }
  if (linkPrev) {
    seoTags.link.push({
      rel: 'prev',
      href: linkPrev,
    });
  }
  if (linkNext) {
    seoTags.link.push({
      rel: 'next',
      href: linkNext,
    });
  }
  if (baseUrl) {
    seoTags.link.push({
      rel: 'canonical',
      href: resolveUrl(baseUrl),
    });
    seoTags.meta.push({
      hid: 'og:url',
      property: 'og:url',
      content: resolveUrl(baseUrl),
    });
  }
  return seoTags;
};

export interface ServiceMetaParams {
  data?: any;
  baseData: PageInterface;
  image?: string;
}

export const getUrlMeta = (url: string): PageInterface => {
  const baseUrl = `https://agrosimex.pl${url === '/' ? '' : url}`;
  return { baseUrl };
};

export const getPaginationMeta = (
  { pagination, route, baseData }: { pagination: Pagination, route: number, baseData: PageInterface },
): PageInterface => {
  const metaInfo: PageInterface = { ...baseData };

  if (pagination?.currentPage && pagination?.totalPages) {
    const { currentPage, totalPages } = pagination;

    if (route) {
      if (currentPage > 1 && currentPage <= totalPages) metaInfo.linkPrev = `${metaInfo.baseUrl}?page=${currentPage - 1}`;
      if (currentPage < totalPages) metaInfo.linkNext = `${metaInfo.baseUrl}?page=${currentPage + 1}`;
    }
    if (currentPage > 1 && currentPage <= totalPages) {
      metaInfo.baseUrl += `?page=${currentPage}`;
    }
  }
  return metaInfo;
};

export const getStoryMeta = ({ data, baseData }: ServiceMetaParams): PageInterface => {
  const metaInfo: PageInterface = { ...baseData };
  if (data?.content?.SEO?.title || data?.title) metaInfo.metaTitle = data.content.SEO.title || data.title;
  if (data?.content?.SEO?.description) metaInfo.metaDescription = data.content.SEO.description;
  if (data?.content?.ogimage?.filename) metaInfo.metaImage = data.content.ogimage.filename;
  return metaInfo;
};

export const getMagentoMeta = ({ data, baseData, image }: ServiceMetaParams): PageInterface => {
  const metaInfo: PageInterface = { ...baseData };
  if (data?.meta_title || data?.name) metaInfo.metaTitle = data.meta_title || data.name;
  if (data?.meta_description || data?.description) metaInfo.metaDescription = data.meta_description || data.description.html;
  if (data?.meta_keywords || data?.meta_keyword) metaInfo.metaKeywords = data.meta_keywords || data.meta_keyword;
  if (data?.meta_keywords || data?.meta_keyword) metaInfo.metaKeywords = data.meta_keywords || data.meta_keyword;
  if (image) metaInfo.metaImage = image;
  return metaInfo;
};
