var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SfSearchBar',{staticClass:"relative h-14 searchbar",class:{ 'search-bar-active': _vm.inputNotEmpty,
            'searchbar--gray': _vm.background === 'gray',
            'searchbar--white': _vm.background === 'white'},attrs:{"placeholder":'Znajdź interesujące Cię treści',"aria-label":"Blog search","value":_vm.term},on:{"input":function($event){return _vm.handleInput($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.inputNotEmpty)?_c('SfButton',{staticClass:"p-0 search-button w-[--spacer-xl] sm:w-[--spacer-2xl] h-full rounded-[50px] bg-transparent search-remove-button",attrs:{"aria-label":"Clear search"},on:{"click":function($event){return _vm.removeSearch()}}},[_c('SfImage',{attrs:{"src":_vm.addBasePath('/icons/close.svg'),"alt":"header search","width":16,"height":16}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-between h-full dropdown-box"},[_c('div',{staticClass:"h-full py-2 mr-4 flex items-center w-full absolute top-full left-0 right-0 md:static"},[_c('div',{staticClass:"desktop-only w-px h-full mr-4 bg-[#cdcdcd] opacity-70"}),_vm._v(" "),_c('ProductDropdown',{staticClass:"z-[2] h-full w-full",attrs:{"title":_vm.selectedCategory ? _vm.selectedCategory.name : 'Kategorie bloga',"size":"md","variant":"minimal","no-icon":"","no-content-padding":""}},[_c('div',{staticClass:"max-h-80 overflow-y-auto"},[_vm._l((_vm.categories),function(mainCategory){return [_vm._l((_vm.categoryTree[mainCategory.full_slug].children),function(childCategory,index){return [(index === 0)?_c('div',{key:childCategory.uuid + 'main'},[_c('span',{staticClass:"flex justify-between items-center py-1 px-5 hover:bg-[#F5F5F5] font-bold text-black cursor-pointer",class:{
                      'text-primary bg-[#F5F5F5]': _vm.fullSlug === childCategory.full_slug || _vm.storySlug === childCategory.full_slug,
                      'bg-white': _vm.fullSlug !== childCategory.full_slug,
                    },on:{"click":function($event){return _vm.selectCategory(mainCategory)}}},[_vm._v("\n                    "+_vm._s(mainCategory.name)+"\n                  ")])]):_vm._e(),_vm._v(" "),_c('div',{key:childCategory.uuid},[_c('span',{staticClass:"flex justify-between items-center py-1 px-11 text-[#4D4D4D] hover:bg-[#F5F5F5] text-sm cursor-pointer",class:{
                      'text-primary font-bold bg-[#F5F5F5]': _vm.fullSlug === childCategory.full_slug || _vm.storySlug === childCategory.full_slug,
                      'bg-white': _vm.fullSlug !== childCategory.full_slug,
                    },on:{"click":function($event){return _vm.selectCategory(childCategory)}}},[_vm._v("\n                    "+_vm._s(childCategory.name)+"\n                  ")])])]})]})],2)])],1),_vm._v(" "),(_vm.inputNotEmpty)?_c('SfButton',{staticClass:"search-button h-full px-6 bg-primary hover:bg-black rounded-[3.125rem] smartphone-only",attrs:{"aria-label":"Do search"},on:{"click":function($event){return _vm.handleSearch()}}},[_c('SfImage',{staticClass:"search-icon",attrs:{"alt":"header search","src":_vm.addBasePath('/icons/search.svg'),"width":16,"height":16}})],1):_vm._e(),_vm._v(" "),_c('SfButton',{staticClass:"p-0 search-button w-[--spacer-2xl] h-full bg-primary hover:bg-black rounded-[3.125rem] desktop-only",attrs:{"aria-label":"Do search","disabled":!_vm.inputNotEmpty},on:{"click":function($event){return _vm.handleSearch()}}},[_c('SfImage',{attrs:{"src":_vm.addBasePath('/icons/search.svg'),"alt":"header search","width":24,"height":24}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }