






















































import BlogArticleTile from '~/modules/storyblok/components/Blog/BlogArticleTile.vue';
import {
  defineComponent, nextTick, ref, useFetch, useMeta, onMounted, toRef,
} from '@nuxtjs/composition-api';
import { useStoryblokBridge } from '@storyblok/nuxt-2';
import CategoryPagination from '~/modules/catalog/category/components/pagination/CategoryPagination.vue';
import type { Pagination } from '~/composables/types';
import { addBasePath } from '~/helpers/addBasePath';
import { useUiHelpers } from '~/composables';
import {
  getMetaInfo, getStoryMeta, getUrlMeta, getPaginationMeta,
} from '~/helpers/getMetaInfo';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import { useUser } from '~/modules/customer/composables/useUser';
import useStoryblokView from '~/modules/storyblok/composables/useStoryblokView';

export default defineComponent({
  name: 'BlogCategoryFile',
  components: {
    CategoryPagination,
    BlogArticleTile,
  },
  layout: 'basic',
  props: {
    currentStory: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const currentStory = toRef(props, 'currentStory');
    const uiHelpers = useUiHelpers();
    const categoryUUID = currentStory.value?.uuid;
    const { getArticleByCategoryUUID } = useStoryblokView();
    const { addTags } = useCache();
    const { isAuthenticated } = useUser();

    const pagination = ref<Pagination>({
      currentPage: 0,
      totalPages: 0,
    });
    const queryPage = () => Number(uiHelpers.resolveQuery()?.page ?? 1);
    const curentPage = () => (pagination.value.totalPages !== 0 && queryPage() > pagination.value.totalPages ? 1 : queryPage());

    const metaInfo = () => {
      const urlMeta = getUrlMeta(uiHelpers.resolvePath());
      const paginationMeta = getPaginationMeta({ pagination: pagination.value, route: queryPage(), baseData: urlMeta });
      return getStoryMeta({ data: currentStory.value, baseData: paginationMeta });
    };
    useMeta(() => getMetaInfo({ page: metaInfo() }));

    const getTotalPages = (headers) => Math.ceil(Number(headers.total) / Number(headers['per-page']));

    const articles = ref([]);

    const { fetch } = useFetch(async () => {
      try {
        if (!categoryUUID) throw new Error('categoryUUID not provided');
        const { stories, headers } = await getArticleByCategoryUUID({
          uuid: categoryUUID,
          params: {
            per_page: 9,
            page: curentPage(),
          },
        });
        pagination.value.currentPage = curentPage();
        pagination.value.totalPages = getTotalPages(headers);
        articles.value = stories;

        if (!isAuthenticated.value) {
          const storiesTags = articles.value.map((article) => ({
            prefix: CacheTagPrefix.View,
            value: article.full_slug,
          }));

          addTags(storiesTags);
        }
      } catch (error) {
        console.log('error', error);
      }
    });
    fetch();

    onMounted(() => {
      if (currentStory.value?.id) {
        useStoryblokBridge(currentStory.value.id, (evStory) => { (currentStory.value = evStory); }, { preventClicks: true });
      }
    });

    const postsContainerElement = ref<HTMLElement | null>(null);

    const goToPage = (page: number) => {
      uiHelpers.changePage(page, false);
      fetch();

      nextTick(() => {
        postsContainerElement.value?.scrollIntoView({ block: 'start' });
        window.scrollBy(0, -200);
      });
    };

    return {
      queryPage,
      curentPage,
      goToPage,
      addBasePath,
      pagination,
      articles,
      postsContainerElement,
    };
  },
  head: {},
});
