














































































































import {
  defineComponent, ref, computed, useRouter, PropType, onMounted, nextTick,
} from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import {
  SfImage, SfSearchBar, SfButton,
} from '@storefront-ui/vue';
import type { CategoryTree, Category } from '~/helpers/blog/traverseCategories';
import ProductDropdown from '~/modules/catalog/product/components/ProductDropdown.vue';
import { scrollToElement } from '~/helpers/scrollToElement';

export default defineComponent({
  components: {
    SfImage,
    SfSearchBar,
    SfButton,
    ProductDropdown,
  },
  props: {
    categories: {
      type: Array as PropType<Category[]>,
      required: true,
    },
    categoryTree: {
      type: Object as PropType<CategoryTree>,
      required: true,
    },
    background: {
      type: String as PropType<'white' | 'gray'>,
      default: 'gray',
    },
    storySlug: {
      type: String as PropType<string>,
      required: true,
    },
    storyName: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup({ storySlug, storyName }) {
    const router = useRouter();
    const term = ref('');

    const selectedCategory = ref(null);
    const selectCategory = (category) => {
      selectedCategory.value = category;
    };
    const handleInput = (searchTerm: string) => {
      term.value = searchTerm;
    };
    const fullSlug = computed(() => {
      const { fullPath } = router.currentRoute;
      if (fullPath.startsWith('/')) {
        return fullPath.slice(1) as string;
      }
      return fullPath as string;
    });
    const handleSearch = () => {
      const slug = selectedCategory.value?.full_slug || fullSlug.value;
      router.push({ path: `/${slug}`, query: { search: term.value }, hash: 'category-content' });
    };
    const removeSearch = () => {
      if (!term.value) return;
      term.value = '';
      const query = { ...router.currentRoute.query };
      delete query.search;
      router.replace({ query });
    };

    const inputNotEmpty = computed(() => term.value.trim() !== '');

    onMounted(() => {
      const { query, hash } = router.currentRoute;

      if (query.search) {
        term.value = query.search as string;
      }

      // Set selected category
      selectedCategory.value = {
        full_slug: storySlug,
        name: storyName,
        uuid: new Date().toDateString(),
      };

      // Scroll to category content if hash
      nextTick(() => {
        if (hash === '#category-content') {
          scrollToElement('#category-content');
        }
      });
    });

    return {
      removeSearch,
      addBasePath,
      term,
      fullSlug,
      inputNotEmpty,
      handleInput,
      handleSearch,
      selectCategory,
      selectedCategory,
    };
  },
});
